<template>
  <div class="constrain pb-16">
    <div class="pink-box">
      <h2 class="text-white font-bold" v-text="t('bottomText.heading')" />
    </div>
    <div class="dark-box" v-html="t('bottomText.text')" />
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();

    return { t };
  },
};
</script>

<style lang="scss" scoped>
  .pink-box {
    @apply bg-violet p-8;

    @screen sm {
      @apply mx-12;
    }

    @screen md {
      @apply mx-16;
    }

    @screen lg {
      @apply mx-32;
    }

    h2 {
      margin-bottom: 1em;

      @screen md {
        width: 85%;
      }
    }
  }

  .dark-box {
    @apply bg-violet-dark p-8 -mt-8;

    @screen sm {
      @apply ml-24 mr-16;
    }

    @screen md {
      @apply ml-24 mr-20;
    }

    @screen lg {
      @apply ml-48 mr-36;
    }
  }
</style>
