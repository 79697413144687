<template>
  <div>
    <swiper
      :slides-per-view="1.5"
      :space-between="25"
      :breakpoints="breakpoints"
      :centered-slides="true"
      @swiper="onSwiper"
    >
      <swiper-slide
        v-for="campaign in loadedCampaigns"
        :key="campaign.id"
      >
        <campaign :campaign="campaign" />
      </swiper-slide>
    </swiper>
    <div class="next-btn" @click="nextSlide">
      <arrow-right class="h-4" />
    </div>
    <div class="prev-btn" @click="prevSlide">
      <arrow-left class="h-4" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper';
import Campaign from '@/components/parts/Campaign.vue';
import ArrowRight from '@/assets/images/arrow_right.svg';
import ArrowLeft from '@/assets/images/arrow_left.svg';

export default {
  components: {
    ArrowRight, ArrowLeft, Campaign, Swiper, SwiperSlide,
  },
  props: {
    campaigns: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props) {
    const swiperRef = ref(null);
    const loadedCampaigns = ref([]);
    const onSwiper = (swiper) => {
      loadedCampaigns.value = props.campaigns;
      swiperRef.value = swiper;
    };

    const nextSlide = () => {
      swiperRef.value.slideNext();
    };
    const prevSlide = () => {
      swiperRef.value.slidePrev();
    };

    const breakpoints = {
      640: {
        slidesPerView: 2,
        spaceBetween: 50,
        centeredSlides: false,
      },
      950: {
        slidesPerView: 3,
        spaceBetween: 50,
        centeredSlides: false,
      },
      1500: {
        slidesPerView: 4,
        spaceBetween: 50,
        centeredSlides: false,
      },
    };

    return {
      onSwiper,
      loadedCampaigns,
      modules: [Navigation],
      nextSlide,
      prevSlide,
      breakpoints,
    };
  },
};
</script>

<style lang="scss" scoped>
.next-btn,
.prev-btn {
  @apply absolute top-1/2 z-50 cursor-pointer hidden;

  @screen sm {
    @apply block;
  }
}

.prev-btn {
  left: -2rem;
}

.next-btn {
  right: -2rem;
}
</style>
