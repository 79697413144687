<template>
  <section v-view class="py-28">
    <div class="constrain flex justify-between items-baseline mb-8">
      <h1 class="text-xl sm:text-2xl sm:mb-16" v-text="title" />
      <div class="flex justify-start items-baseline text-white">
        <GridIcon
          :class="{ 'text-active': layout === 'grid' }"
          class="layout-icon hover:text-active w-8 sm:w-16 mr-4 sm:mr-8 relative top-3 sm:top-7"
          @click="layout = 'grid'"
        />
        <SliderIcon
          :class="{ 'text-active': layout === 'slider' }"
          class="w-12 sm:w-20 layout-icon"
          @click="layout = 'slider'"
        />
      </div>
    </div>

    <div v-if="layout === 'grid'" class="constrain grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
      <Campaign
        v-for="campaign in campaigns"
        :key="campaign.id"
        :campaign="campaign"
      />
    </div>
    <div v-else class="relative sm:mx-16">
      <campaign-swiper :campaigns="campaigns" />
    </div>

    <VoteCounter
      v-view
      :amount="favoriteAmount"
      :max-amount="charity ? 3 : 5"
      class="animate counter"
    />
  </section>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import GridIcon from '@/assets/images/grid_active.svg';
import SliderIcon from '@/assets/images/grid_slider.svg';
import Image from '@/components/utils/Image.vue';
import Campaign from '@/components/parts/Campaign.vue';
import VoteCounter from '@/components/parts/VoteCounter.vue';
import CampaignSwiper from '@/components/parts/CampaignSwiper.vue';

export default {
  name: 'DOOHAward',
  components: {
    CampaignSwiper,
    Campaign,
    Image,
    GridIcon,
    SliderIcon,
    VoteCounter,
  },
  props: {
    charity: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const campaigns = computed(() => {
      if (props.charity) {
        return store.state.charityCampaigns;
      }
      return store.state.campaigns;
    });
    const favoriteAmount = computed(() => {
      if (props.charity) {
        return store.state.favoriteCharityCampaigns.length;
      }
      return store.state.favoriteCampaigns.length;
    });

    let title = ref('DOOH-Award');
    if (props.charity) {
      title = 'Charity Award';
    }

    return {
      store,
      favoriteAmount,
      layout: ref('grid'),
      title,
      campaigns,
    };
  },
};
</script>

<style lang="scss" scoped>
.layout-icon {
  cursor: pointer;
  transition: color 0.25s ease;

  &:hover {
    color: var(--color-active);
  }
}

section.view-in--threshold .counter {
  right: 2rem;
  bottom: 5rem;
}
</style>
