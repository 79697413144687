<template>
  <div>
    <div class="py-28">
      <div class="constrain">
        <h1 class="mb-12">
          Voting
        </h1>
        <div>
          <h2 class="text-white font-bold">
            Auswahl DOOH-Award
            <span class="text-active">{{ selectedDooh.length }}</span>
            / 5
          </h2>
          <div v-if="!selectedDooh.length" class="mt-12">
            <strong>
              {{ t('vote.noneSelected') }}
            </strong>
          </div>
          <div v-else class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8 mt-20">
            <Campaign
              v-for="campaign in selectedDooh"
              :key="campaign.id + '-selected'"
              :campaign="campaign"
              small
            />
          </div>
        </div>
      </div>
    </div>

    <div class="bg-blue-dark py-28">
      <div class="constrain">
        <h2 class="text-white font-bold">
          Auswahl Charity-Award
          <span class="text-blue">{{ selectedCharity.length }}</span>
          / 3
        </h2>
        <div v-if="!selectedCharity.length" class="mt-12">
          <strong>
            {{ t('vote.noneSelected') }}
          </strong>
        </div>
        <div v-else class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8 mt-20">
          <Campaign
            v-for="campaign in selectedCharity"
            :key="campaign.id + '-selected'"
            :campaign="campaign"
            style="--color-active: var(--color-blue)"
            small
          />
        </div>
      </div>
    </div>

    <VoteForm />
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import Campaign from '@/components/parts/Campaign.vue';
import VoteForm from '@/components/parts/VoteForm.vue';

export default {
  components: { Campaign, VoteForm },
  props: { charity: { type: Boolean, default: false } },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const selectedDooh = computed(() => store.state.favoriteCampaigns);
    const selectedCharity = computed(() => store.state.favoriteCharityCampaigns);

    return {
      t,
      selectedDooh,
      selectedCharity,
    };
  },
};
</script>
