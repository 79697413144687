<template>
  <div class="constrain py-28">
    <h2 class="text-white font-bold mb-12">
      Voting abgeben
    </h2>
    <div class="row row-12">
      <div class="col-12 md:col-5">
        <div v-html="t('vote.formText')" />
      </div>
      <div class="col-12 mt-12 sm:mt-0 md:col-7">
        <form class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8" @submit.prevent="submit">
          <input v-model="firstName" placeholder="Vorname">
          <input v-model="lastName" placeholder="Nachname">
          <input v-model="email" placeholder="E-Mail" class="md:col-span-2">
          <input v-model="company" placeholder="Firma" class="md:col-span-2">
          <button
            :disabled="sending
              || !readyForSending"
            class="md:col-span-2"
          >
            Voting senden
          </button>
          <div v-if="result" :class="'md:col-span-2 result ' + result.type" v-text="result.message" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const firstName = ref('');
    const lastName = ref('');
    const email = ref('');
    const company = ref('');
    const result = ref(null);
    const sending = ref(false);

    const readyForSending = computed(() => (store.state.favoriteCampaigns.length > 0
        || store.state.favoriteCharityCampaigns.length > 0)
        && firstName.value !== '' && lastName.value !== '' && email.value !== '' && company.value !== '');

    return {
      t,
      store,
      firstName,
      lastName,
      email,
      company,
      result,
      sending,
      readyForSending,
    };
  },
  methods: {
    async submit() {
      this.sending = true;
      try {
        const result = { type: 'success', message: '' };
        if (this.$store.state.favoriteCampaigns.length) {
          const doohRequest = await fetch(`${this.$store.state.api}/ajax/polling/new_entry`, {
            method: 'POST',
            body: JSON.stringify({
              poll: 'dooh',
              first_name: this.firstName,
              last_name: this.lastName,
              email: this.email,
              company: this.company,
              vote: this.$store.state.favoriteCampaigns,
            }),
          });
          const doohResult = await doohRequest.json();
          if (doohResult.type === 'error') {
            result.type = 'error';
            result.message = doohResult.message;
          } else if (doohResult.type === 'success') {
            result.type = 'success';
            result.message = this.t('vote.success');
          }
        }
        if (this.$store.state.favoriteCharityCampaigns.length) {
          const charityRequest = await fetch(`${this.$store.state.api}/ajax/polling/new_entry`, {
            method: 'POST',
            body: JSON.stringify({
              poll: 'charity',
              first_name: this.firstName,
              last_name: this.lastName,
              email: this.email,
              company: this.company,
              vote: this.$store.state.favoriteCharityCampaigns,
            }),
          });
          const charityResult = await charityRequest.json();
          if (charityResult.type === 'error') {
            result.type = 'error';
            if (result.message !== charityResult.message) {
              result.message += ` ${charityResult.message}`;
            }
          } else if (charityResult.type === 'success') {
            result.type = 'success';
            if (result.message !== this.t('vote.success')) {
              result.message += this.t('vote.success');
            }
          }
        }
        this.result = result;
      } catch (err) {
        console.error(err, err.message, err.response);
        this.result = { type: 'error', message: this.t('vote.error') };
      }
      this.sending = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.result {
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  font-weight: bold;

  &.success {
    background: var(--color-active);
  }

  &.error {
    background: var(--color-red);
  }
}
</style>
