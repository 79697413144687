<template>
  <div class="mt-8 sm:mt-0">
    <Hero id="hero" />
    <div class="constrain" v-html="t('intro.text')" />
    <DOOHAward id="dooh-award" ref="doohAwardSection" />
    <div class="bg-blue-dark">
      <DOOHAward
        id="charity-award"
        charity
        class="py-28"
        style="--color-active: var(--color-blue);"
      />
    </div>
    <Voting id="voting" />
    <TextBoxes id="info" />
  </div>
</template>

<script lang="ts">
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useGtm } from '@gtm-support/vue-gtm';
import Hero from '../parts/Hero.vue';
import DOOHAward from '../parts/DOOHAward.vue';
import Voting from '../parts/Voting.vue';
import VoteCounter from '../parts/VoteCounter.vue';
import TextBoxes from '../parts/TextBoxes.vue';

export default {
  name: 'LandingPage',
  components: {
    TextBoxes,
    DOOHAward,
    Hero,
    Voting,
    VoteCounter,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const gtm = useGtm();

    onMounted(() => {
      store.dispatch('FETCH_CAMPAIGNS');
      gtm.trackView('Dooh Award', '/');
    });

    return { store, t };
  },
};
</script>

<style lang="scss" scoped>

</style>
