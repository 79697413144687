<template>
  <div class="counter">
    {{ amount }} /
    {{ maxAmount }}
    <Heart class="w-4 inline ml-2" />
  </div>
</template>

<script>
import Heart from '@/assets/images/heart_active.svg';

export default {
  components: { Heart },
  props: {
    amount: {
      type: Number,
      required: true,
    },
    maxAmount: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.counter {
  @apply bg-active py-2 px-4;
  @apply rounded-3xl;

  position: fixed;
  right: -100%;
  bottom: 5rem;
  transition: all .3s ease-in-out;
  z-index: 10;
}
</style>
